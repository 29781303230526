<template>
  <div>
    <div class="fixed inset-0 darkblue">
      <div class="register-wrapper">
        <h1 class="mb-2 sm:mb-4 text-3xl text-white font-light">Регистрация</h1>

        <label for="name" class="label">Имя</label>
        <input v-model="form.name"
               id="name"
               class="input"
               placeholder="Алина"
               @blur="$v.form.name.$touch()">
        <div v-if="$v.form.name.$error"
             class="form-error">
          <p v-if="!$v.form.name.required">
            Это поле не может быть пустым</p>
        </div>

        <label for="surname" class="label">Фамилия</label>
        <input v-model="form.surname"
               id="surname"
               class="input"
               placeholder="Кравченко"
               @blur="$v.form.surname.$touch()">
        <div v-if="$v.form.surname.$error"
             class="form-error">
          <p v-if="!$v.form.surname.required">
            Это поле не может быть пустым</p>
        </div>

        <label for="email" class="label">Электронная почта</label>
        <input v-model="form.email"
               type="email"
               id="email"
               class="input"
               placeholder="alina@example.com"
               @blur="$v.form.email.$touch()">
        <div v-if="$v.form.email.$error"
             class="form-error">
          <p v-if="!$v.form.email.required">
            Это поле не может быть пустым</p>
          <p v-else-if="!$v.form.email.email">
            Email указан с ошибкой :(</p>
        </div>

        <label for="pass" class="label">Пароль</label>
        <input v-model="form.password"
               type="password"
               id="pass"
               class="input"
               placeholder="••••••••"
               @blur="$v.form.password.$touch()">
        <div v-if="$v.form.password.$error"
             class="form-error">
          <p v-if="!$v.form.password.required">
            Это поле не может быть пустым</p>
          <p v-else-if="!$v.form.password.minLength">
            Длина пароля должна быть не менее 8 символов</p>
        </div>

        <label for="passConf" class="label">Повторите пароль</label>
        <input v-model="form.passwordConfirmation"
               type="password"
               id="passConf"
               class="input"
               placeholder="••••••••"
               @blur="$v.form.passwordConfirmation.$touch()">
        <div v-if="$v.form.passwordConfirmation.$error"
             class="form-error">
          <p v-if="!$v.form.passwordConfirmation.required">
            Это поле не может быть пустым</p>
          <p v-else-if="!$v.form.passwordConfirmation.sameAs">
            Пароль и его подтверждение не совпадают!</p>
        </div>

        <button class="mt-10 px-3 py-2 bg-white rounded"
                @click="register">Зарегистрироваться</button>
        <router-link :to="{name: 'PageLogin'}"
                     class="mt-8 text-sm font-light text-white">
          <u>У меня уже есть аккаунт</u></router-link>
      </div>
    </div>

    <keypress key-event="keyup" :multiple-keys="keyboard.register" @success="register" />
  </div>
</template>

<script>
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
import Keypress from 'vue-keypress';

export default {
  components: { Keypress },
  data () {
    return {
      form: {
        name: null,
        surname: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        position: 'student',
      },
      keyboard: {
        register: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
        ],
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      passwordConfirmation: {
        required,
        sameAs: sameAs('password'),
      },
    },
  },
  methods: {
    register () {
      this.$v.form.$touch();
      if (!this.$v.$anyError) {
        // console.log(`Имя: ${this.form.name}\nФамилия: ${this.form.surname}\nEmail: ${this.form.email}\nPass: ${this.form.password}\nPass conf: ${this.form.passwordConfirmation}`)
        this.$store.dispatch('auth/registerUser', this.form)
            .then(() => alert('Регистрация прошла успешно!\nВ ближайшее время Георгий пришлет тебе ссылку с кодом подтверждения, и по ней ты сможешь залогиниться.'))
            .catch((res) => {
              const m = res.response.data.message || res.message;
              alert(m);
            });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.register-wrapper {
  @apply flex flex-col items-center justify-center;
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 639px) {
    width: 80%;
  }

  @media (min-width: 640px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    width: 25%;
  }

  @media (min-width: 1536px) {
    width: 18%;
  }
}

.darkblue {
  background: #111122;
}

.input {
  @apply w-full p-2 rounded-lg;
}

.label {
  @apply w-full mt-3 sm:mt-4 mb-1 sm:mb-2 font-semibold text-sm text-white;
}

.form-error {
  @apply w-full mt-1 text-xs font-semibold text-red-600;
}
</style>
